import { FC, useState } from 'react';
import { IconSearchRegular32 } from '@croquiscom/zds';
import { vars } from '@croquiscom-pvt/zds/dist/tokens';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';

import { Gray_Light_100, Gray_Light_300, Gray_Light_400, Gray_Light_White, Typography } from '@common/styles';
import {
  BannerGroup,
  CategoryList,
  Navigation,
  ShopInfo,
  useComponentList,
  useStoreHomeUBL,
} from '@domains/storehome-common';

import { ComponentList } from '../component-list';
import { ShopInfoComponent } from '../information';
import { ShopMainBanner } from '../main-banner';
import { FilterParam } from '../types';
import { useBannerGroupPreview } from './useBannerGroupPreview';

export interface StoreHomeViewProps {
  shop_info: ShopInfo;
  banner_group: BannerGroup | null;
  category_list: CategoryList[];
  is_preview?: boolean;
}

export const StoreHomeView: FC<StoreHomeViewProps> = ({ shop_info, banner_group, category_list, is_preview }) => {
  const { query } = useRouter();

  const { id, name } = shop_info;
  const { bannerClickUBL, categoryClickUBL, zigzinFilterClickUBL } = useStoreHomeUBL(id);
  const hrefCallback = (category_id: string) => `/${query.main_domain}/category/${category_id}`;

  const [filter_params, setFilterParams] = useState({
    check_button_item_ids: [] as string[],
    sub_filter_id_list: [] as string[],
    sorting_item_id: null as string | null,
  });
  const params = {
    shop_id: id,
    ...filter_params,
  };

  const { fetchNextPage, ...component_list_props } = useComponentList(params);
  const applied_banner_group = useBannerGroupPreview(is_preview ?? false, banner_group);

  const exists_banner = !!applied_banner_group;

  const onParamsChange = (change_param: FilterParam) => {
    setFilterParams(change_param);

    if (filter_params.sub_filter_id_list.length !== change_param.sub_filter_id_list.length) {
      zigzinFilterClickUBL(Boolean(change_param.sub_filter_id_list.length));
    }
  };

  return (
    <ScrollWrap>
      {applied_banner_group != null && (
        <ShopMainBanner is_preview={is_preview} banner_group={applied_banner_group} sendUBL={bannerClickUBL} />
      )}
      <ShopInfoComponent shop_info={shop_info} is_cover_view={exists_banner} />
      <Divider />
      <Navigation navigation_list={category_list} hrefCallback={hrefCallback} sendUBL={categoryClickUBL} />
      {shop_info.show_search_button && (
        <SearchLinkWrap>
          <SearchLink href={`/${query.main_domain}/search`}>
            <IconSearchRegular32 width={24} height={24} color={Gray_Light_400} />
            <span className={Typography.BODY_16_SEMIBOLD}>{name}에서 검색</span>
          </SearchLink>
        </SearchLinkWrap>
      )}
      <ComponentList
        type='store'
        params={params}
        {...component_list_props}
        onFetchNextPage={fetchNextPage}
        onParamsChange={onParamsChange}
      />
    </ScrollWrap>
  );
};

const ScrollWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  background-color: ${vars.color.staticWhite};
  min-height: 100vh;
`;

const Divider = styled.hr`
  margin: 0;
  border: 0;
  background-color: ${Gray_Light_100};
  padding: 0;
  width: 100%;
  height: 8px;
  z-index: 10;
`;

const SearchLinkWrap = styled.div`
  padding: 8px 16px;
  background-color: ${Gray_Light_White};
  z-index: 10;
`;

const SearchLink = styled.a`
  display: flex;
  align-items: center;
  height: 36px;
  padding: 6px 16px;
  border-radius: 100px;
  background-color: ${Gray_Light_100};
  cursor: pointer;

  span {
    color: ${Gray_Light_300};
  }
`;
