import { FC } from 'react';
import { IconStarBold32 } from '@croquiscom/zds';
import { Text } from '@croquiscom-pvt/zds';
import styled from '@emotion/styled';

import { Gray_Light_900, Gray_Light_White } from '@common/styles';
import { numberInTenThousandWithFloor } from '@common/utils';
import { CouponIcon, ShopInfo, useStoreHomeUBL } from '@domains/storehome-common';
import { useLandingPopup } from '@common/overlay-manager';

interface Props {
  shop_info: ShopInfo;
  is_cover_view: boolean;
}

export const ShopInfoComponent: FC<Props> = ({ shop_info, is_cover_view }) => {
  const { id, name, typical_image_url, age_list, style_list, comment, representative_coupon, bookmark_count } =
    shop_info;
  const { couponDownloadClickUBL } = useStoreHomeUBL(id);
  const { open: openLandingPopup } = useLandingPopup();
  const styleList = style_list.map((style) => style.value);
  const description = [...age_list, ...styleList];

  const handleBookmarkClick = () => {
    openLandingPopup();
  };

  return (
    <Root is_cover={is_cover_view}>
      <Wrap>
        <InfoWrap>
          <ThumbnailCover>
            <Thumbnail src={typical_image_url} />
          </ThumbnailCover>
          <Information>
            <Name as='h2' variant='Head_20_Bold' v3Color='gray900'>
              {name}
            </Name>
            <Text as='p' variant='Caption_12_Medium' v3Color='gray300'>
              {description.join('·')}
            </Text>
          </Information>
          <BookmarkWrap onClick={handleBookmarkClick}>
            <IconStarBold32 color={Gray_Light_900} stroke={Gray_Light_White} />
            <BookmarkCount variant='Caption_11_Medium' v3Color='gray300'>
              {numberInTenThousandWithFloor(bookmark_count)}
            </BookmarkCount>
          </BookmarkWrap>
        </InfoWrap>

        {comment && (
          <Comment as='p' variant='Body_13_Medium' v3Color='gray700'>
            {comment}
          </Comment>
        )}
        {representative_coupon && (
          <CouponIcon
            link={`/coupon/box/${id}`}
            title={{ is_html: true, text: representative_coupon.title }}
            sendUBL={couponDownloadClickUBL}
          />
        )}
      </Wrap>
    </Root>
  );
};

const Root = styled.div<{ is_cover: boolean }>`
  position: relative;
  padding: 0 0 16px;
  z-index: 10;
  background-color: ${Gray_Light_White};

  ${({ is_cover }) =>
    is_cover &&
    `
    margin-top: 0;
    padding-top: 16px;
  `}
`;

const Wrap = styled.div`
  padding: 0 16px 0;
`;

const InfoWrap = styled.div`
  display: flex;
  padding: 0 0 16px 0;
`;

const ThumbnailCover = styled.div`
  border-radius: 50%;
  background-color: ${Gray_Light_White};
  width: 56px;
  height: 56px;
  overflow: hidden;
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
`;

const Information = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 7px 12px;
`;

const Name = styled(Text)`
  margin: 0 0 4px 0;
  line-height: 24px;
`;

const BookmarkWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  width: 48px;
`;

const BookmarkCount = styled(Text)`
  line-height: 14.4px;
`;

const Comment = styled(Text)`
  padding-bottom: 16px;
  white-space: pre-line;
`;
